import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import { getRandomIndex } from "../../utils"

const RandomBgImage = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBgimage {
        edges {
          node {
            title
            description
            imgURL
            creditName
            creditPlatform
            creditLink
            weight
          }
        }
      }
    }
  `)

  const imagesList = data.allStrapiBgimage

  let images = []

  imagesList.edges.map(image => {
    const weightedValue = image.node.weight

    _.times(weightedValue, () => {
      images.push(image.node)
    })

  });

  const randomImage = images[getRandomIndex(images.length)]
  // console.log('images: '+ JSON.stringify(images))
  // console.log('randomImage: '+ JSON.stringify(randomImage))
  const randomImageURL = randomImage.imgURL
  const description = randomImage.description ? randomImage.description : ""
  // const creditName = randomImage.creditName ? randomImage.creditName : null
  // const creditPlatform = randomImage.creditPlatform ? randomImage.creditPlatform : ""
  // const creditLink = randomImage.creditLink ? randomImage.creditLink : null

  // let imageBlock

  // const imageSolo = (
  //   <div className="bg-img-wrapper">
  //     <img src={randomImageURL} alt={description} />
  //   </div>
  // )
  //
  // if (creditName) {
  //   if (creditLink) {
  //     const platform = ' on ' + creditPlatform
  //
  //     imageBlock = (
  //       <div>
  //         {imageSolo}
  //         <figcaption>
  //           <a href={creditLink} rel="noreferrer" target="_blank">
  //             Photo by {creditName}{platform}
  //           </a>
  //         </figcaption>
  //       </div>
  //     )
  //   } else {
  //     imageBlock = (
  //       <div>
  //         {imageSolo}
  //         <figcaption>
  //           Photo by {creditName}{platform}
  //         </figcaption>
  //       </div>
  //     )
  //   }
  //
  // } else {
  //   imageBlock = (
  //     <>
  //       {imageSolo}
  //     </>
  //   )
  // }

  return (
    <div className="bg image">
      <div className="bg-img-wrapper">
        <img src={randomImageURL} alt={description} />
      </div>
    </div>
  )
}

export default RandomBgImage


