import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import AnchorWithWrap from "@static/icons/anchor-with-wrap.svg"
import Spyglass from "@static/icons/spyglass.svg"
import Lantern from "@static/icons/lantern.svg"
import Parchment from "@static/icons/parchment.svg"
// import UnfurledParchment from "@static/icons/unfurled-parchment.svg"
import Bell from "@static/icons/ships-bell.svg"

const OverviewNav = () => {
  // Need to close the offcanvas nav on click
  // zf-close="offcanvas-nav"

  // Change bg classname on state
  // className="{'bg-light': randomBackground != 'bg-video'}"

  const data = useStaticQuery(graphql`
    {
      allStrapiNavitem(sort: {fields: order, order:ASC}) {
        edges {
          node {
            id
            title
            slug
            order
            overviewnav
            animation
          }
        }
      }
    }
  `)

  return (
    <nav
      id="homepage-nav"
      role="navigation"
      aria-label="Homepage Navigation"
      className="bg-light"
    >
      {data.allStrapiNavitem.edges.map(item => {
        const animation = item.node.animation ? item.node.animation : 'fade'
        const slug = item.node.slug ? item.node.slug : ""
        const title = item.node.title ? item.node.title : item.node.title
        const id = item.node.id
        const overviewnav = item.node.overviewnav

        let Icon;

        if (slug === "about") {
          Icon = (<AnchorWithWrap />);
        } else if (slug === "projects") {
          Icon = (<Spyglass />);
        } else if (slug === "services") {
          Icon = (<Lantern />);
        } else if (slug === "blog") {
          Icon = (<Parchment />);
        } else if (slug === "contact") {
          Icon = (<Bell />);
        } else {
          Icon = (<Parchment />);
        }

        if (overviewnav) {
          return (
            <AniLink
              key={id}
              animation={animation}
              to={`/${slug}`}
              duration={1}
              hex="#00558b"
              title={title}
              aria-label={title}
            >
              {Icon}
              <strong>{title}</strong>
            </AniLink>
          )
        } else {
          return false
        }
      })}
    </nav>
  )
}

export default OverviewNav


