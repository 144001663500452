import React from "react"

const BgSolidAnimatedGradient = () => (
  <div className="bg">
    <div id="loading" className="loading loading_wrap">
      <div className="box">
        <div className="inner">
        </div>
      </div>
    </div>
  </div>
)

export default BgSolidAnimatedGradient


