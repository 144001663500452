import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Quote from "./Quote"

const RandomQuote = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiQuote {
        edges {
          node {
            id
            quote
            author
          }
        }
      }
    }
  `)

  const randomQuoteData = data.allStrapiQuote.edges[Math.floor(Math.random()*data.allStrapiQuote.edges.length)];
  const quote = randomQuoteData.node.quote ? randomQuoteData.node.quote : ""
  const author = randomQuoteData.node.author ? randomQuoteData.node.author : ""

  return (
    <Quote
      quote={quote}
      author={author}
    />
  )
}

export default RandomQuote