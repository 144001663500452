import React, { useEffect } from "react"

const BgGeometricAnimated = () => {
  const refreshDuration = 10000;
  let refreshTimeout;
  let numPointsX;
  let numPointsY;
  let unitWidth;
  let unitHeight;
  let points;

  // const [windowSize, resizeWindow] = useState();


  function onLoad() {
    var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    let innerWidth;
    let innerHeight;

    if (typeof window !== "undefined" && window) {
      innerWidth = window.innerWidth;
      innerHeight = window.innerHeight;
    }

    svg.setAttribute("width", innerWidth);
    svg.setAttribute("height", innerHeight);

    if (document.querySelector("#bg-geometric-animated")) {
      document.querySelector("#bg-geometric-animated").appendChild(svg);
    }

    var unitSize = (innerWidth+innerHeight)/20;
    numPointsX = Math.ceil(innerWidth/unitSize)+1;
    numPointsY = Math.ceil(innerHeight/unitSize)+1;
    unitWidth = Math.ceil(innerWidth/(numPointsX-1));
    unitHeight = Math.ceil(innerHeight/(numPointsY-1));

    points = [];

    for(var y = 0; y < numPointsY; y++) {
      for(var x = 0; x < numPointsX; x++) {
        points.push({x:unitWidth*x, y:unitHeight*y, originX:unitWidth*x, originY:unitHeight*y});
      }
    }

    randomize();
    for(var i = 0; i < points.length; i++) {
      if(points[i].originX !== 0 && points[i].originX !== unitWidth*(numPointsX-1)) {
        points[i].x = points[i].originX + Math.random()*unitWidth-unitWidth/2;
      }
      if(points[i].originY !== 0 && points[i].originY !== unitHeight*(numPointsY-1)) {
        points[i].y = points[i].originY + Math.random()*unitHeight-unitHeight/2;
      }
    }

    for(var j = 0; j < points.length; j++) {
      if(points[j].originX !== unitWidth*(numPointsX-1) && points[j].originY !== unitHeight*(numPointsY-1)) {
        var topLeftX = points[j].x;
        var topLeftY = points[j].y;
        var topRightX = points[j+1].x;
        var topRightY = points[j+1].y;
        var bottomLeftX = points[j+numPointsX].x;
        var bottomLeftY = points[j+numPointsX].y;
        var bottomRightX = points[j+numPointsX+1].x;
        var bottomRightY = points[j+numPointsX+1].y;

        var rando = Math.floor(Math.random()*2);

        for(var n = 0; n < 2; n++) {
          var polygon = document.createElementNS(svg.namespaceURI, "polygon");

          if(rando===0) {
            if(n===0) {
              polygon.point1 = j;
              polygon.point2 = j+numPointsX;
              polygon.point3 = j+numPointsX+1;
              polygon.setAttribute("points",topLeftX+","+topLeftY+" "+bottomLeftX+","+bottomLeftY+" "+bottomRightX+","+bottomRightY);
            } else if(n===1) {
              polygon.point1 = j;
              polygon.point2 = j+1;
              polygon.point3 = j+numPointsX+1;
              polygon.setAttribute("points",topLeftX+","+topLeftY+" "+topRightX+","+topRightY+" "+bottomRightX+","+bottomRightY);
            }
          } else if(rando===1) {
            if(n===0) {
              polygon.point1 = j;
              polygon.point2 = j+numPointsX;
              polygon.point3 = j+1;
              polygon.setAttribute("points",topLeftX+","+topLeftY+" "+bottomLeftX+","+bottomLeftY+" "+topRightX+","+topRightY);
            } else if(n===1) {
              polygon.point1 = j+numPointsX;
              polygon.point2 = j+1;
              polygon.point3 = j+numPointsX+1;
              polygon.setAttribute("points",bottomLeftX+","+bottomLeftY+" "+topRightX+","+topRightY+" "+bottomRightX+","+bottomRightY);
            }
          }
          polygon.setAttribute("fill","rgba(0,0,0,"+(Math.random()/3)+")");
          var animate = document.createElementNS("http://www.w3.org/2000/svg","animate");
          animate.setAttribute("fill","freeze");
          animate.setAttribute("attributeName","points");
          animate.setAttribute("dur",refreshDuration+"ms");
          animate.setAttribute("calcMode","linear");
          polygon.appendChild(animate);
          svg.appendChild(polygon);
        }
      }
    }

    refresh();
  }

  function randomize() {
    for(var i = 0; i < points.length; i++) {
      if(points[i].originX !== 0 && points[i].originX !== unitWidth*(numPointsX-1)) {
        points[i].x = points[i].originX + Math.random()*unitWidth-unitWidth/2;
      }
      if(points[i].originY !== 0 && points[i].originY !== unitHeight*(numPointsY-1)) {
        points[i].y = points[i].originY + Math.random()*unitHeight-unitHeight/2;
      }
    }
  }

  function refresh() {
    randomize();
    for(var i = 0; i < document.querySelector("#bg-geometric-animated svg").childNodes.length; i++) {
      var polygon = document.querySelector("#bg-geometric-animated svg").childNodes[i];
      var animate = polygon.childNodes[0];
      if(animate.getAttribute("to")) {
        animate.setAttribute("from",animate.getAttribute("to"));
      }
      animate.setAttribute("to",points[polygon.point1].x+","+points[polygon.point1].y+" "+points[polygon.point2].x+","+points[polygon.point2].y+" "+points[polygon.point3].x+","+points[polygon.point3].y);
      animate.beginElement();
    }
    refreshTimeout = setTimeout(
      () => {
        refresh();
      },
      refreshDuration);
  }

  function onResize() {
    document.querySelector("#bg-geometric-animated svg").remove();
    clearTimeout(refreshTimeout);
    onLoad();
  }

  useEffect(() => {
    onLoad()
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (typeof window != "undefined" && window) {
    window.addEventListener("resize", function() {
      onResize();
    });
  }

  return (
    <div
      id="bg-geometric-animated"
      className="bg geometric animated"
    >

    </div>
  )
}

export default BgGeometricAnimated
