import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import _ from "lodash"
import { getRandomIndex } from "../../utils"

const RandomBgVideo = () => {
  const data = useStaticQuery(graphql`
    {
      allStrapiBgvideo {
        edges {
          node {
            title
            description
            mp4URL
            webmURL
            weight
          }
        }
      }
    }
  `)

  const videosList = data.allStrapiBgvideo

  let videos = []

  videosList.edges.map(video => {
    const weightedValue = video.node.weight

    _.times(weightedValue, () => {
      videos.push(video.node)
    })

  });

  const randomVideo = videos[getRandomIndex(videos.length)]
  // console.log('videos: '+ JSON.stringify(videos))
  // console.log('randomVideo: '+ JSON.stringify(randomVideo))
  const randomVideoWebM = randomVideo.mp4URL;
  const randomVideoMp4 = randomVideo.webmURL;

  return (
    <div className="bg video">
      <video preload="auto" poster="" muted autoPlay loop>
        <source src={randomVideoWebM} type="video/webm" />
        <source src={randomVideoMp4} type="video/mp4" />
      </video>
    </div>
  )
}

export default RandomBgVideo


