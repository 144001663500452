import React from "react"
import Copyright from "./Copyright"

const OverviewFooter = () => (
  <footer id="footer" className="overview">
    <Copyright />
  </footer>
)

export default OverviewFooter;

