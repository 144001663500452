import React from "react"

const Quote = ({ quote, author, url }) => {
  let citeBlock

  if (url) {
    citeBlock = (
      <cite>
        <a href={url} rel="noreferrer" target="_blank">
          {author}
        </a>
      </cite>
    )
  } else {
    citeBlock = (
      <cite>{author}</cite>
    )
  }


  return (
    <blockquote>
      {quote}
      <footer>
        {citeBlock}
      </footer>
    </blockquote>
  )
}

export default Quote;

