import moment from "moment";

// Dates
export function dateYear(date) {
  return moment(date).format("YYYY");
}

export function dateMonthDayYear(date) {
  return moment(date).format("MMMM Do[,] YYYY");
}

export function dateMonthYear(date) {
  return moment(date).format("MM[/]YYYY");
}

// URLs
export function createSlug(title) {
  return title
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-');
}

// Responsiveness
export function getWindowWidth() {
  if (typeof window !== 'undefined') {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  } else {
    return false;
  }
}

export function isMobile() {
  return getWindowWidth() < 768;
}

export function isTablet() {
  return getWindowWidth() >= 768 && getWindowWidth() < 1024;
}

export function isDesktop() {
  return getWindowWidth() >= 1024;
}

// Random index
export function getRandomIndex(length) {
  return Math.floor(Math.random() * length);
}
// Random number in between two numbers
export function getRandomNumberBetween(min, max, step) {
  return Math.floor(Math.random() * (max - min + step)) + min;
}

// Shuffle Array
// https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array
export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array
}

export function formatLink(linkHtmlString) {
  // Add an aria-label using the Title content
  return linkHtmlString.replace(/title="(.*?)"/g, (match, title) => `${match} aria-label="${title}"`);
}

export function formatMarkdownEmbeddedVideo(linkHtmlString) {
  // Add an aria-label using the Title content
  return linkHtmlString.replace(/title="(.*?)"/g, (match, title) => `${match} aria-label="${title}"`);
}

export function getColorLuminance(hex, lum) {

	// validate hex string
	hex = String(hex).replace(/[^0-9a-f]/gi, '');
	if (hex.length < 6) {
		hex = hex[0]+hex[0]+hex[1]+hex[1]+hex[2]+hex[2];
	}
	lum = lum || 0;

	// convert to decimal and change luminosity
	var rgb = "#", c, i;
	for (i = 0; i < 3; i++) {
		c = parseInt(hex.substr(i*2,2), 16);
		c = Math.round(Math.min(Math.max(0, c + (c * lum)), 255)).toString(16);
		rgb += ("00"+c).substr(c.length);
	}

	return rgb;
}

export function shadeColor(color, percent) {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}

export function colorShade(col, amt) {
  col = col.replace(/^#/, '')
  if (col.length === 3) col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2]

  let [r, g, b] = col.match(/.{2}/g);
  ([r, g, b] = [parseInt(r, 16) + amt, parseInt(g, 16) + amt, parseInt(b, 16) + amt])

  r = Math.max(Math.min(255, r), 0).toString(16)
  g = Math.max(Math.min(255, g), 0).toString(16)
  b = Math.max(Math.min(255, b), 0).toString(16)

  const rr = (r.length < 2 ? '0' : '') + r
  const gg = (g.length < 2 ? '0' : '') + g
  const bb = (b.length < 2 ? '0' : '') + b

  return `#${rr}${gg}${bb}`
}

