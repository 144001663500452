import React from "react"
import { graphql } from "gatsby"
// import Moment from 'react-moment';
// import 'moment-timezone';
import ReactMarkdown from "react-markdown"
import Typewriter from 'typewriter-effect'
import { shuffleArray } from '../utils/index'
import Layout from "@components/layout"
import SEO from "@components/seo"
import OverviewFooter from "@components/Footer/OverviewFooter"
import OverviewNav from "@components/Nav/OverviewNav"
// import ArticlesComponent from "@components/articles"
import RandomBackground from "@components/Backgrounds/RandomBackground"
import RandomQuote from "@components/Quotes/RandomQuote"

export const query = graphql`
  query OverviewPageQuery($id: Int) {
    strapiOverview(strapiId: { eq: $id }) {
      strapiId
      title
      description
      mainStatementTitle
      mainStatementContent
      featuredprojects {
        id
        titleshort
        heroURL
      }
    }
    allStrapiStatement {
      edges {
        node {
          id
          content
        }
      }
    }
    allStrapiProject(sort: {fields: [order], order: DESC}) {
      edges {
        node {
          id
          titleshort
          slug
          date
          showdate
          theme
          thumbnailURL
          heroURL
          featured
          order
        }
      }
    }
  }
`

const IndexPage = ({ data }) => {
  const overview = data.strapiOverview
  const statements = data.allStrapiStatement

  const title = overview.title ? overview.title : ""
  const description = overview.description ? overview.description : ""
  const mainStatementTitle = overview.mainStatementTitle ? overview.mainStatementTitle : ""
  const mainStatementContent = overview.mainStatementContent ? overview.mainStatementContent : ""

  // let featuredProjectsList = []
  //
  // projectsList.edges.map(item => {
  //   if (item.node.featured) {
  //     // console.log('Add to Featured Projects list: ' + item.node.titleshort)
  //     return featuredProjectsList.push(item.node)
  //   }
  // })




  // const featuredProjects = overview.featuredprojects

  // console.log(JSON.stringify(overview));

  // alert(process.env.NODE_ENV)
  // alert(process.env.STRAPI_URL)
  // console.log('Timezone guess: ' + Moment.tz.guess());


  // Change CSS file based on time of day
  // function getTimeZone() {
  //     var offset = new Date().getTimezoneOffset(), o = Math.abs(offset);
  //     return (offset < 0 ? "+" : "-") + ("00" + Math.floor(o / 60)).slice(-2) + ":" + ("00" + (o % 60)).slice(-2);
  // }
  //
  // const hours = new Date().getHours()
  // const isDaytime = hours > 6 && hours < 20
  //
  // console.log('Timezone: '+ getTimeZone());
  // console.log('Hour: '+ hours);
  // console.log('Is Daytime?: '+ isDaytime);

  //   <script>
  // <!--
  // function getStylesheet() {
  //       var currentTime = new Date().getHours();
  //       if (0 <= currentTime&&currentTime < 5) {
  //        document.write("<link rel='stylesheet' href='night.css' type='text/css'>");
  //       }
  //       if (5 <= currentTime&&currentTime < 11) {
  //        document.write("<link rel='stylesheet' href='morning.css' type='text/css'>");
  //       }
  //       if (11 <= currentTime&&currentTime < 16) {
  //        document.write("<link rel='stylesheet' href='day.css' type='text/css'>");
  //       }
  //       if (16 <= currentTime&&currentTime < 22) {
  //        document.write("<link rel='stylesheet' href='evening.css' type='text/css'>");
  //       }
  //       if (22 <= currentTime&&currentTime <= 24) {
  //        document.write("<link rel='stylesheet' href='night.css' type='text/css'>");
  //       }
  // }
  //
  // getStylesheet();
  // -->
  // </script>
  //
  // <noscript><link href="main.css" rel="stylesheet"></noscript>

  // Get the statements and put them into an array format to work with Typewriter plugin
  let typewriterStatements = []
  statements.edges.map(item => {
    // console.log(item.node.content)
    return typewriterStatements.push(item.node.content)
  })
  // Unshuffled array of statements
  // console.log('typewriterStatements: ', typewriterStatements)

  // Use utility shuffleArray to randomize the order of the statements
  const typewriterStatementsShuffled = shuffleArray(typewriterStatements)
  // console.log('typewriterStatementsShuffled: ', typewriterStatementsShuffled)

  const Interdisciplinary = () => (
    <Typewriter
      options={{
        strings: typewriterStatementsShuffled,
        autoStart: true,
        delay: 70,
        loop: true,
        pauseFor: 2500,
        // deleteSpeed: 150,
      }}
    />
  )

  // const featuredProjectsList = featuredprojects

  // console.log('featuredProjects: ', featuredProjects)

  return (
    <Layout>
      <main className="grid-content">
        <SEO
          title={title}
          description={description}
        />
        <RandomBackground />
        <div id="home" className="page">
          <section>
            <div className="">
              <div className="main-content">
                <div id="main-statement">
                  <h1>{mainStatementTitle}</h1>
                  <h2>
                    <Interdisciplinary />
                  </h2>
                  <ReactMarkdown children={mainStatementContent} />
                </div>
              </div>
            </div>
          </section>
          <section>
            <div className="">
              <div className="main-content">
                <br />
                <br />
                <OverviewNav />

                <RandomQuote />
                <br />
                <br />
                <br />
                <br />
                <br />


                {/* Featured Testimonial & link to project

                {featuredProjectsList.map(item => {
                  const featuredProject = item
                  const featuredProjectID = featuredProject.id
                  const featuredProjectTitleShort = featuredProject.titleshort ? featuredProject.titleshort : ''
                  const featuredProjectTheme = featuredProject.theme ? featuredProject.theme : '#00558b'
                  const featuredProjectSlug = `/projects/${featuredProject.slug}`
                  // const featuredProjectDate = featuredProject.date ? featuredProject.date : ''

                  let featuredProjectThumbnail
                  const featuredProjectAltText = featuredProjectTitleShort + ' hero'

                  // if (
                  //   project.thumbnail &&
                  //   project.thumbnail.childImageSharp &&
                  //   project.thumbnail.childImageSharp.fluid
                  // ) {
                  //   thumbnail = (
                  //     <Img
                  //       fluid={project.thumbnail.childImageSharp.fluid}
                  //       alt={projectTitle}
                  //     />
                  //   )
                  if (featuredProject.thumbnailURL) {
                    featuredProjectThumbnail = (
                      <img src={featuredProject.thumbnailURL} alt={featuredProjectAltText} style={imgStyleTemp} />
                    )
                  } else {
                    featuredProjectThumbnail = (
                      <img src="https://via.placeholder.com/1380x500/0000FF/808080" alt={featuredProjectAltText} style={imgStyleTemp} />
                    )
                  }
                  // {thumbnail}

                  return (
                    <div className="grid-block align-center" key={featuredProjectID}>
                      <AniLink
                        to={featuredProjectSlug}
                        title={featuredProjectTitleShort}
                        paintDrip
                        duration={1}
                        hex={featuredProjectTheme}
                      >
                        {featuredProjectThumbnail}
                        <div className="thumbnail-hover-details">
                          <div className="details-inner">
                            <h3>{featuredProjectTitleShort}</h3>
                          </div>
                        </div>
                      </AniLink>
                    </div>
                  )}
                )}*/}

              </div>
            </div>
          </section>
        </div>
        <OverviewFooter />
      </main>
    </Layout>
  )
}

export default IndexPage
