import React, { useEffect } from "react"
import Trianglify from "trianglify"

const BgTrianglify = () => {
  useEffect(() => loadTrianglifyPattern(), []);

  function loadTrianglifyPattern() {
    // console.log("loadTrianglifyPattern fired");
    let innerWidth;
    let innerHeight;

    if (typeof window !== "undefined" && window) {
      innerWidth = window.innerWidth;
      innerHeight = window.innerHeight;
    }

    var pattern = Trianglify({
      width: innerWidth,
      height: innerHeight,
      // variance: $scope.getRandomNumberBetween(0, 1, 0.5),
      // cell_size: $scope.getRandomNumberBetween(80, 200, 40),
      x_colors: ["#4ebb9a", "30a9df", "#00558b", "#173965"]
    });

    var div = document.createElement("div");

    if (document.querySelector("#bg-geometric")) {
      document.querySelector("#bg-geometric").appendChild(div);
      document.querySelector("#bg-geometric div").appendChild(pattern.canvas());
    }
  }

  function reloadTrianglifyPattern() {
    document.querySelector("#bg-geometric div").remove();

    setTimeout(function() {
      loadTrianglifyPattern();
    }, 200);
  }

  if (typeof window != "undefined" && window) {
    window.addEventListener("resize", function() {
      reloadTrianglifyPattern();
    });
  }

  return (
    <div
      id="bg-geometric"
      className="bg geometric"
    >

    </div>
  )
}

export default BgTrianglify


