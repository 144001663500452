import React from "react"
import { isDesktop, getRandomIndex } from "../../utils"
import BgSolid from "./BgSolid"
import BgSolidAnimatedGradient from "./BgSolidAnimatedGradient"
// import BgVideo from "./BgVideo"
import RandomBgVideo from "./RandomBgVideo"
import RandomBgImage from "./RandomBgImage"
import BgGeometricAnimated from "./BgGeometricAnimated"
import BgTrianglify from "./BgTrianglify"
// import BgImage from "./BgImage"

const RandomBackground = () => {
  const backgrounds = [
    {value: "bg-solid"},
    {value: "bg-solid-animated-gradient"},
    // {value: "bg-image"},
    // {value: "bg-video"},
    // {value: "bg-trianglify"},
    // {value: "bg-geometric-animated"}
  ];

  const backgroundsMobile = [
    {value: "bg-solid"},
    {value: "bg-solid-animated-gradient"},
    // {value: "bg-image"},
    // {value: "bg-trianglify"},
    // {value: "bg-geometric-animated"}
  ];

  let randomBackgroundSelected;
  let background;

  if (isDesktop()) {
    randomBackgroundSelected = backgrounds[getRandomIndex(backgrounds.length)].value;
  } else {
    randomBackgroundSelected = backgroundsMobile[getRandomIndex(backgroundsMobile.length)].value;
  }

  switch (randomBackgroundSelected) {
    case "bg-solid":
      background = <BgSolid />;
      break;
    case "bg-solid-animated-gradient":
      background = <BgSolidAnimatedGradient />;
      break;
    case "bg-image":
      background = <RandomBgImage />;
      break;
    case "bg-video":
      background = <RandomBgVideo />;
      break;
    case "bg-trianglify":
      background = <BgTrianglify />;
      break;
    case "bg-geometric-animated":
      background = <BgGeometricAnimated />;
      break;
    default:
      background = <BgSolid />;
      break;
  }

  return background;
}

export default RandomBackground


