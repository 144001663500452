import React from "react"

const BgSolid = () => (
  <div className="bg solid">

  </div>
)

export default BgSolid


